














































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ImportFromServiceResponse } from '@/types/course.type';
import { TableHeader } from '@/types/general.type';

@Component
export default class ServiceCoursesDetailsComponent extends Vue {
  @Prop(Object) readonly data!: ImportFromServiceResponse;

  headerClass = 'grey darken-4 secondary--text pa-3 qb-table__header';

  get tablesData() {
    return Object.keys(this.data).map((key) => ({
      title: this.$t(`titles.${key}`),
      items: this.data[key],
    }));
  }

  get headers(): TableHeader[] {
    return [
      {
        text: this.$t('tableHeaders.courseName'),
        align: 'center',
        value: 'courseName',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.studyPlanId'),
        value: 'studyPlanId',
        align: 'center',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.studyPlan'),
        value: 'studyPlan',
        align: 'center',
        sortable: false,
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.IHId'),
        align: 'center',
        value: 'ibnHaithamId',
        sortable: false,
        class: this.headerClass,
      },
    ];
  }

  limitOption = [5, 10, 20, 50];

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
    };
  }
}
